@import "src/styles/common.scss";

.roadmap-container {
    display: flex;
    position: relative;
    width: 100%;
    margin-top: 50px;
    justify-content: center;
}

.row {
    width: 100%;
}

.roadmap-title {
    display: flex;
    font-family: "Gotham Rounded";
    width: 100%;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 25px;
}

.roadmap-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.roadmap {
    display: flex;
    flex: 1 0 21%;
    margin: 10px;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    filter: drop-shadow(0px 12px 0px #281a43);
}

.banana-opened {
    height: 150px;
    margin-top: 50px;
    margin-bottom: 50px;
    animation: float-banana 6s infinite;
}

@keyframes float-banana {
    0% {
        transform: translatey(-15px);
    }
    50% {
        transform: translatey(15px);
    }
    100% {
        transform: translatey(-15px);
    }
}

.banana-closed {
    height: 150px;
    margin-top: 50px;
    margin-bottom: 50px;
    &:hover {
        -webkit-animation: shake-lr 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
        animation: shake-lr 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    }
}

@-webkit-keyframes shake-lr {
    0%,
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    10% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
    20%,
    40%,
    60% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
    30%,
    50%,
    70% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }
    80% {
        -webkit-transform: rotate(-8deg);
        transform: rotate(-8deg);
    }
    90% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
}
@keyframes shake-lr {
    0%,
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    10% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
    20%,
    40%,
    60% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
    30%,
    50%,
    70% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }
    80% {
        -webkit-transform: rotate(-8deg);
        transform: rotate(-8deg);
    }
    90% {
        -webkit-transform: rotate(8deg);
        transform: rotate(8deg);
    }
}

.milestone-title {
    font-family: "Gotham Rounded";
    text-align: center;
}

.milestone-description {
    margin-top: 30px;
    font-size: 18px;
    text-align: center;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .roadmap-wrapper {
        flex-direction: column;
    }
}
