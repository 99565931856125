@import "src/styles/common.scss";

.sticker-container {
    display: flex;
    width: 100%;
    margin-top: 50px;
    height: 200px;
    .sticker {
        display: flex;
        flex: 1 0 21%;
        margin: 10px;
        height: 175px;
        filter: drop-shadow(0px 12px 0px #281a43);
    }
    .sticker-1 {
        animation: float-sticker-1 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        width: 100%;
        height: 100%;
        margin-top: 70px;
        margin-right: -5px;
        background-color: #5a3995;
        border: 3px solid #815dae;
        .sticker-text {
            border: none;
            background-color: transparent;
            margin: 0;
        }
    }
    .sticker-2 {
        animation: float-sticker-2 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        width: 100%;
        height: 100%;
        margin-right: -5px;
        margin-top: 20px;
        background-color: #45257f;
        border: 3px solid #6131b9;
        .sticker-text {
            border: none;
            background-color: transparent;
            margin: 0;
        }
    }
    .sticker-3 {
        animation: float-sticker-3 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        width: 100%;
        height: 100%;
        margin-top: 100px;
        margin-left: -5px;
        background-color: #5a3995;
        border: 3px solid #815dae;
        .sticker-text {
            border: none;
            background-color: transparent;
            margin: 0;
        }
    }
    .sticker-4 {
        animation: float-sticker-4 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        width: 100%;
        height: 100%;
        margin-top: 50px;
        margin-left: -5px;
        background-color: #45257f;
        border: 3px solid #6131b9;
        .sticker-text {
            border: none;
            background-color: transparent;
            margin: 0;
        }
    }
}

.test {
    width: 100%;
}

.row {
    width: 100%;
}

@keyframes float-sticker-1 {
    0% {
        transform: rotate(-5deg) translatey(0px);
    }
    50% {
        transform: rotate(-3deg) translatey(-10px);
    }
    100% {
        transform: rotate(-5deg) translatey(0px);
    }
}

@keyframes float-sticker-2 {
    0% {
        transform: rotate(5deg) translatey(-10px);
    }
    50% {
        transform: rotate(3deg) translatey(0px);
    }
    100% {
        transform: rotate(5deg) translatey(-10px);
    }
}

@keyframes float-sticker-3 {
    0% {
        transform: rotate(5deg) translatey(0px);
    }
    50% {
        transform: rotate(3deg) translatey(-10px);
    }
    100% {
        transform: rotate(5deg) translatey(0px);
    }
}

@keyframes float-sticker-4 {
    0% {
        transform: rotate(-5deg) translatey(-10px);
    }
    50% {
        transform: rotate(-3deg) translatey(0px);
    }
    100% {
        transform: rotate(-5deg) translatey(-10px);
    }
}

.sticker-image {
    position: absolute;
    width: 40%;
    top: -30px;
    max-width: 120px;
}

.sticker-image-burn {
    position: absolute;
    width: 30%;
    top: -70px;
    max-width: 120px;
}

.sticker-text {
    font-family: "Gotham Rounded";
    font-size: 20px;
    color: #ab8bce;
}

.feature-container {
    display: flex;
    margin-top: 100px;
    height: 300px;
    .feature {
        display: flex;
        flex: 1 0 21%;
        margin: 10px;
        height: 300px;
        filter: drop-shadow(0px 12px 0px #281a43);
    }
    .feature-1 {
        animation: float-feature-1 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        width: 100%;
        height: 300px;
        margin-top: 50px;
        background-color: #fff6ca;
        border: 3px solid #7758b0;
        .feature-text {
            border: none;
            background-color: transparent;
            margin: 0;
        }
    }
    .feature-2 {
        animation: float-feature-2 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        width: 100%;
        height: 300px;
        background-color: #e8ae54;
        border: 3px solid #7758b0;
        .feature-text {
            border: none;
            background-color: transparent;
            margin: 0;
        }
    }
    .feature-3 {
        animation: float-feature-3 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        width: 100%;
        height: 300px;
        margin-top: 50px;
        margin-left: 5px;
        background-color: #fbcf00;
        border: 3px solid #7758b0;
        .feature-text {
            border: none;
            background-color: transparent;
            margin: 0;
        }
    }
    .feature-4 {
        animation: float-feature-4 6s ease-in-out infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        width: 100%;
        height: 300px;
        margin-top: 30px;
        margin-left: 5px;
        background-color: #e8bea1;
        border: 3px solid #7758b0;
        .feature-text {
            border: none;
            background-color: transparent;
            margin: 0;
        }
    }
}

@keyframes float-feature-1 {
    0% {
        transform: rotate(5deg) translatey(-10px);
    }
    50% {
        transform: rotate(3deg) translatey(0px);
    }
    100% {
        transform: rotate(5deg) translatey(-10px);
    }
}

@keyframes float-feature-2 {
    0% {
        transform: rotate(-5deg) translatey(10px);
    }
    50% {
        transform: rotate(-3deg) translatey(0px);
    }
    100% {
        transform: rotate(-5deg) translatey(10px);
    }
}

@keyframes float-feature-3 {
    0% {
        transform: rotate(-5deg) translatey(-10px);
    }
    50% {
        transform: rotate(-3deg) translatey(0px);
    }
    100% {
        transform: rotate(-5deg) translatey(-10px);
    }
}

@keyframes float-feature-4 {
    0% {
        transform: rotate(5deg) translatey(0px);
    }
    50% {
        transform: rotate(3deg) translatey(-10px);
    }
    100% {
        transform: rotate(5deg) translatey(0px);
    }
}

.feature-text {
    font-family: "Gotham Rounded";
    font-size: 20px;
    color: #301f4f;
    padding-left: 20px;
    text-align: center;
    padding-right: 20px;
}

.feature-text-description {
    font-size: 16px;
    font-family: "Gotham Rounded Book";
}

.feature-line {
    border-bottom: 2px solid #301f4f;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .sticker-container {
        position: relative;
        height: auto;
        margin-top: 100px;
    }

    .sticker-1,
    .sticker-2,
    .sticker-3,
    .sticker-4 {
        margin-top: 70px !important;
        justify-content: center;
        width: 75% !important;
    }

    .sticker {
        margin-top: 90px !important;
        justify-content: center;
        width: 100%;
    }

    .feature-container {
        position: relative;
        height: auto;
        margin-top: 50px;
    }

    .feature-1,
    .feature-2,
    .feature-3,
    .feature-4 {
        margin-top: 70px !important;
        justify-content: center;
        width: 75% !important;
    }

    .feature {
        margin-top: 90px !important;
        justify-content: center;
        width: 100%;
    }

    .sticker-image-burn {
        height: 140px;
    }
}
