@import "src/styles/common.scss";

.navbar-container {
    display: flex;
    justify-content: center;
    position: fixed;
    margin-bottom: 75px;
    height: 70px;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: var(--theme-navbar-background);
    transition: 1s;
    width: 50%;
    z-index: 1000;
    -webkit-animation: fade-in-top 3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-top 3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  

.navbar-without-background {
    display: flex;
    height: 100%;
    z-index: 1000;
    padding-left: 30px;
    padding-right: 30px;
    border-end-start-radius: 20px;
    border-end-end-radius: 20px;
    transition: 1s;
}

.navbar-with-background {
    display: flex;
    height: 100%;
    z-index: 1000;
    background-color: #45257f;
    border-end-start-radius: 20px;
    border-end-end-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
    filter: drop-shadow(0px 12px 20px #281a43);
    transition: 1s;
}

@-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.navbar {
    display: flex;
    justify-content: space-between;
}

.navbar {
    margin: auto;
    //min-height: calc(100vh - 700px);
}
@media (min-width: 768px) {
    .navbar {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .navbar {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .navbar {
        width: 1170px;
    }
}

.logo-container {
    display: flex;
    flex: 100%;
    justify-content: flex-start;
    margin-left: 30px;
}

.logo {
    font-weight: bold;
    color: #6700ff;
}

.casette {
    cursor: pointer;
}

.links-container {
    display: flex;
    flex: 65%;
    align-items: center;
}

.links {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    font-size: 22px;
}

.link {
    cursor: pointer;
}

.active-link {
    color: var(--theme-link-hover-color);
    border: 3px solid var(--theme-link-hover-color);
    border-radius: 10px;
    padding: 3px 10px 3px 10px;
    justify-content: center;
    transition: 1s;
}

.active-scroll-link {
    color: red;
}

.hamburger-menu {
    display: none;
}

.hamburger-menu-dropdown {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 70px;
    min-width: 100%;
    justify-content: space-evenly;
    font-size: 30px;
    height: 50%;
    z-index: 1000;
    background-color: #5a3995;
    -webkit-animation: slit-in-vertical 0.45s ease-out both;
    animation: slit-in-vertical 0.45s ease-out both;
}

.language-menu-dropdown {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    bottom: 70px;
    justify-content: space-evenly;
    font-size: 30px;
    z-index: 1000;
    animation: navbar_slit-in-vertical__PPb26 0.45s ease-out both;
    -webkit-animation: slit-in-vertical 0.45s ease-out both;
    animation: slit-in-vertical 0.45s ease-out both;
}

.responsive-links-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    text-align: center;
    align-items: center;
}

@-webkit-keyframes slit-in-vertical {
    0% {
        -webkit-transform: translateZ(-800px) rotateY(90deg);
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0;
    }
    54% {
        -webkit-transform: translateZ(-160px) rotateY(87deg);
        transform: translateZ(-160px) rotateY(87deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0);
    }
}
@keyframes slit-in-vertical {
    0% {
        -webkit-transform: translateZ(-800px) rotateY(90deg);
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0;
    }
    54% {
        -webkit-transform: translateZ(-160px) rotateY(87deg);
        transform: translateZ(-160px) rotateY(87deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0);
    }
}

.dark-light-theme {
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: space-around;
    background: #6703fe;
    cursor: pointer;
    border: 5px double;
    margin-right: 30px;
}

.audio-element {
    cursor: pointer;
}

.telegram {
    fill: white;
    border: 5px double;
    height: 36px;
    margin-right: 30px;
    cursor: pointer;
}

.flag-wrapper {
    fill: white;
    border: 5px double;
    height: 51%;
    margin-right: 30px;
    cursor: pointer;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .navbar-without-background {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-container,
    .navbar-with-background {
        width: 100%;
        border-end-start-radius: 0;
        border-end-end-radius: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .navbar {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-right: 30px;
    }

    .links {
        display: none;
    }

    .dark-light-theme {
        display: none;
    }

    .dark-light-theme-mobile-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dark-light-theme-mobile {
        display: flex;
        align-items: center;
        width: 35%;
        justify-content: space-evenly;
        background: #6703fe;
        cursor: pointer;
        border: 5px double;
    }

    .theme-wrapper {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    .hamburger-menu {
        display: block;
        justify-content: flex-end;
        font-size: 30px;
        transition: 2s;
        cursor: pointer;
    }

    .telegram {
        display: none;
    }
}
