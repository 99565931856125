@import "src/styles/common.scss";

.header-background {
    position: absolute;
    background: url("bcg2.png");
    width: 100%;
    top: 0;
    background-repeat: no-repeat;
    background-size: 100% 800px;
    background-position: center top;
}

.launch {
    position: fixed;
    height: 100px;
    width: 200%;
    left: -50%;
    top: 50%;
    background-color: #d62e52;
    z-index: 999;
    transform: rotate(5deg);
}

.launch-column {
    flex: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.launch-title {
    font-family: "Gotham Rounded";
    display: flex;
    position: absolute;
    font-size: 72px;
    text-align: center;
    justify-content: center;
    width: 100%;
    white-space: pre;
    height: 100%;
    align-items: center;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .particles {
        display: none;
    }

    .header-background {
        background-size: unset;
    }

    .launch {
        height: 70px;
    }

    .launch-title {
        font-size: 24px;
    }
}
