$mobile: 420px;
$tablet: 768px;

.container {
    margin: auto;
    padding-bottom: 140px;
    padding-top: 140px;
}
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

img {
    max-width: 100%;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.column {
    flex: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}

a {
    text-decoration: none;
    color: var(--theme-page-text);
    display: flex;
    align-items: center;
    border: 3px solid transparent;
    border-radius: 10px;
    padding: 3px 10px 3px 10px;
    justify-content: center;
    &:hover {
        color: var(--theme-link-hover-color);
        border: 3px solid var(--theme-link-hover-color);
        border-radius: 10px;
        padding: 3px 10px 3px 10px;
        justify-content: center;
        transition: 1s;
    }
}

h2 {
    //margin-bottom: 25px;
    width: 100%;
    text-align: center;
}

ul {
    padding: 0;
}

li {
    list-style-type: none;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .column {
        flex: none;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    .row {
        display: grid;
    }

    .container {
        margin-right: 30px;
        margin-left: 30px;
    }
}
