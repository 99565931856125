@import "src/styles/common.scss";

.header-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.row {
    width: 100%;
    flex-wrap: nowrap;
}

.ape-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
}

.ape {
    max-height: 250px;
    position: absolute;
    bottom: 10%;
    right: 24%;
    z-index: 2;
    animation: float-ape 6s ease-in-out infinite;
}

.moon {
    position: absolute;
    max-width: 230px;
    right: 20%;
    top: 5%;
}

.rocket {
    will-change: transform;
    position: absolute;
    height: 75px;
    right: 34%;
    top: 22%;
    z-index: 1;
    animation: circle 6s linear infinite;
}

@keyframes circle {
    0% {
        transform: rotate(0deg) translate(-165px) rotate(45deg);
    }
    100% {
        transform: rotate(360deg) translate(-165px) rotate(45deg);
    }
}

.banana-0 {
    position: absolute;
    z-index: 1;
    height: 65px;
    right: 25%;
    bottom: 3%;
    animation: float-banana-1 6s infinite;
}

.saber {
    position: absolute;
    z-index: 1;
    height: 120px;
    right: 15%;
    bottom: 10%;
    animation: float-saber 6s infinite;
}

@keyframes float-ape {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-20px);
    }
    100% {
        transform: translatey(0px);
    }
}

@keyframes float-banana-1 {
    0% {
        transform: translatey(0px) translateX(0px);
    }
    50% {
        transform: translatey(-30px) translateX(-20px) rotateZ(20deg);
    }
    100% {
        transform: translatey(0px) translateX(0px);
    }
}

@keyframes float-saber {
    0% {
        transform: translatey(0px) rotate(60deg);
    }
    50% {
        transform: translatey(-30px) rotate(30deg);
    }
    100% {
        transform: translatey(0px) rotate(60deg);
    }
}

.banana-1 {
    position: absolute;
    z-index: 1;
    height: 55px;
    right: 60%;
    top: 15%;
    animation: float-banana-2 6s infinite;
}

@keyframes float-banana-2 {
    0% {
        transform: translatey(0px) rotate(210deg);
    }
    50% {
        transform: translatey(-30px) rotate(180deg);
    }
    100% {
        transform: translatey(0px) rotate(210deg);
    }
}

.banana-2 {
    position: absolute;
    z-index: 1;
    height: 40px;
    left: 5%;
    bottom: 0%;
    animation: float-banana-3 6s infinite;
}

@keyframes float-banana-3 {
    0% {
        transform: translatey(0px) rotate(40deg);
    }
    50% {
        transform: translatey(-30px) rotate(60deg);
    }
    100% {
        transform: translatey(0px) rotate(40deg);
    }
}

.header-welcome-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.welcome-message {
    font-family: "Gotham Rounded";
    font-size: 30px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
}

.welcome-description {
    font-family: "Gotham Rounded Book";
    font-size: 18px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
}

.token-contract {
    display: flex;
    flex-direction: column;
    font-family: "Gotham Rounded";
    color: #fbce03;
    font-size: 22px;
    margin-bottom: 30px;
    text-align: center;
}

.token-address {
    font-family: "Gotham Rounded Book";
    font-size: 20px;
    color: white;
}

.ape-in-button {
    cursor: pointer;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    width: 250px;
    font-size: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    letter-spacing: 3px;
    font-weight: bold;
    margin-bottom: 10px;
    border: 7px double var(--theme-button-border);
    border-radius: 10px;
    background-color: var(--theme-button-background);
    color: var(--theme-button-color);
    flex-wrap: wrap;
}

.banana-closed {
    height: 74px;
    left: -14px;
    display: flex;
    position: absolute;
}

.banana-opened {
    height: 74px;
    left: -46px;
    display: flex;
    position: absolute;
}

.button-slippage {
    font-size: 9px;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .token-address {
        font-size: 12px;
    }

    .ape-wrapper {
        margin-top: 50px;
        min-height: 300px;
        height: 100%;
        width: 70%;
    }

    .ape {
        position: absolute;
        left: -16%;
        top: 70%;
        height: 230px;
    }

    .moon {
        left: 0%;
        height: 240px;
    }

    .rocket {
        position: absolute;
        height: 70px;
        top: 30%;
        left: 33%;
    }

    @keyframes circle {
        0% {
            transform: rotate(0deg) translate(-140px) rotate(45deg);
        }
        100% {
            transform: rotate(360deg) translate(-140px) rotate(45deg);
        }
    }

    .banana-0 {
        left: 73%;
        top: 82%;
    }

    .banana-1,
    .banana-2 {
        display: none;
    }
}
