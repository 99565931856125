@import "src/styles/common.scss";

.tokenomics-container {
    display: flex;
    position: relative;
    width: 100%;
    margin-top: 150px;
    justify-content: center;
}

.row {
    width: 100%;
}

.tokenomics-title {
    display: flex;
    font-family: "Gotham Rounded";
    width: 100%;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.tokenomics {
    flex: 1 0 40%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.tokenomics-column-1 {
    flex: 0 0 40%;
    font-size: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    :nth-child(1) {
        background-color: #fbcf00;
    }
    :nth-child(2) {
        background-color: #fff6ca;
    }
    :nth-child(3) {
        background-color: #e8ae54;
    }
}

.tokenomics-column-2 {
    flex: 0 0 40%;
    font-size: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    :nth-child(1) {
        background-color: #e8ae54;
    }
    :nth-child(2) {
        background-color: #e8bea1;
    }
    :nth-child(3) {
        background-color: #fbcf00;
    }
}

.tokenomic {
    font-family: "Gotham Rounded";
    border-radius: 15px;
    padding: 10px 25px 10px 25px;
    margin-bottom: 15px;
    color: #301f4f;
}

.ape-with-bananas {
    position: absolute;
    right: 0%;
    height: 300px;
}

.matic-explorer {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .tokenomics-container {
        margin-top: 125px;
    }

    .ape-with-bananas {
        position: absolute;
        width: 100px;
        bottom: -18%;
        right: -5%;
    }

    .matic-explorer {
        justify-content: center;
    }
}
